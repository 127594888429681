import React, { useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  IonList,
  IonItemDivider,
  IonItem,
  IonInput,
  IonToast,
  IonBackButton,
  IonLoading,
  IonAlert,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonSelect,
  IonSelectOption,
  IonFabList,
} from "@ionic/react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  add,
  trashOutline,
  attachOutline,
  pencilOutline,
  logoWhatsapp,
  qrCode,
  arrowUp,
  calendarOutline,
} from "ionicons/icons";
import { useStorage } from "reactfire";
import ExploreContainer from "../components/ExploreContainer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { needsRefreshState, refreshRootQueryState } from "./atoms";
import UPI from "../components/UPI";
import CreateAppointment from "../components/CreateAppointment";

export interface PatientProps {
  history: any;
  match: any;
}
const PATIENT_QUERY = gql`
  query ($id: ID!) {
    patient(id: $id) {
      id
      name
      phone
      age
      gender
      treatments {
        id
        title
        date
        time
        prescriptions {
          id
          url
        }
      }
      xrays {
        id
        url
      }
      reports {
        id
        url
      }
      miscs {
        id
        url
      }
      temps {
        id
        value
        date
        time
      }
      oxys {
        id
        spo2
        bpm
        date
        time
      }
    }
  }
`;

const ADD_Treatment = gql`
  mutation AddTreatment(
    $pid: String!
    $date: String!
    $time: String!
    $title: String!
  ) {
    addTreatment(pid: $pid, date: $date, time: $time, title: $title) {
      id
      title
      date
      time
    }
  }
`;

const EDIT_PATIENT = gql`
  mutation EditPatient(
    $id: ID
    $name: String!
    $age: Int!
    $gender: String!
    $phone: String!
  ) {
    editPatient(
      id: $id
      name: $name
      age: $age
      gender: $gender
      phone: $phone
    ) {
      result
    }
  }
`;

const ADD_XRAY = gql`
  mutation AddXray($pid: String!, $url: String!) {
    addXray(pid: $pid, url: $url) {
      id
      url
    }
  }
`;

const ADD_REPORT = gql`
  mutation AddReport($pid: String!, $url: String!) {
    addReport(pid: $pid, url: $url) {
      id
      url
    }
  }
`;

const ADD_MISC = gql`
  mutation AddMisc($pid: String!, $url: String!) {
    addMisc(pid: $pid, url: $url) {
      id
      url
    }
  }
`;

const ADD_TEMP = gql`
  mutation AddTemp(
    $pid: String!
    $value: Float!
    $date: String!
    $time: String!
  ) {
    addTemp(pid: $pid, value: $value, date: $date, time: $time) {
      id
      value
      date
      time
    }
  }
`;

const ADD_OXY = gql`
  mutation AddOxy(
    $pid: String!
    $spo2: Float!
    $bpm: Float!
    $date: String!
    $time: String!
  ) {
    addOxy(pid: $pid, spo2: $spo2, bpm: $bpm, date: $date, time: $time) {
      id
      spo2
      bpm
      date
      time
    }
  }
`;

const REMOVE_TEMP = gql`
  mutation RemoveTemp($id: ID!) {
    removeTemperature(id: $id) {
      result
    }
  }
`;

const REMOVE_OXY = gql`
  mutation RemoveOxy($id: ID!) {
    removeOxy(id: $id) {
      result
    }
  }
`;

const REMOVE_PATIENT = gql`
  mutation RemovePatient($id: ID!) {
    removePatient(id: $id) {
      result
    }
  }
`;

const Patient: React.SFC<PatientProps> = ({ match, history }) => {
  const [showCreateAppointment, setShowCreateAppointment] = useState(false);
  const [editPatient] = useMutation(EDIT_PATIENT, {
    onCompleted() {
      setShowToastEdit(true);
      refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const setRefreshRootQuerry = useSetRecoilState(refreshRootQueryState);
  const [removeTemp] = useMutation(REMOVE_TEMP, {
    onCompleted() {
      setShowToast2(true);
      refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [removeOxy] = useMutation(REMOVE_OXY, {
    onCompleted() {
      setShowToast2(true);
      refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [removePatient] = useMutation(REMOVE_PATIENT, {
    onCompleted() {
      setShowToast2(true);
      history.push("/");
      setRefreshRootQuerry(true);
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showToastEdit, setShowToastEdit] = useState(false);
  const [id, setId] = useState<string>();
  const [dValue, setDValue] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [segement, setSegment] = useState("treatment");
  const { loading, error, data, refetch } = useQuery(PATIENT_QUERY, {
    variables: { id: match.params.id },
  });
  const [name, setName] = useState<string>();
  const [age, setAge] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [showQR, setShowQR] = useState<boolean>(false);
  const [needsRefresh, setNeedsRefresh] = useRecoilState(needsRefreshState);
  const [aName, setAName] = useState<string>("");
  const [aNumber, setANumber] = useState<string>("");
  if (needsRefresh) {
    refetch();
    setNeedsRefresh(false);
  }
  const green = { background: "green" };
  const orange = { background: "orange" };
  const red = { background: "red" };
  if (!loading && firstLoad) {
    setFirstLoad(false);
    setName(data.patient.name);
    setAge(data.patient.age);
    setPhone(data.patient.phone);
    setGender(data.patient.gender);
  }
  if (loading || error) return <ExploreContainer />;
  else {
    return (
      <IonPage>
        <IonModal
          isOpen={showCreateAppointment}
          swipeToClose={true}
          onDidDismiss={() => setShowCreateAppointment(false)}
        >
          <CreateAppointment name={aName} phone={aNumber} />
          <IonButton onClick={() => setShowCreateAppointment(false)}>Close</IonButton>
        </IonModal>
        <IonModal isOpen={showEdit} >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Edit {name}</IonTitle>
            </IonToolbar>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                let iage = parseInt(age!, 10);
                editPatient({
                  variables: {
                    id: match.params.id,
                    name,
                    age: iage,
                    gender,
                    phone,
                  },
                });
                setShowEdit(false);
                setName("");
                setAge("");
                setGender("");
                setPhone("");
              }}
            >
              <IonList>
                <IonItemDivider>Patient Name</IonItemDivider>
                <IonItem>
                  <IonInput
                    clearInput
                    pattern={"[a-zA-Z ]*"}
                    value={name}
                    required
                    type={"text"}
                    placeholder="Enter Name"
                    onIonChange={(e) => setName(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItemDivider>Age</IonItemDivider>
                <IonItem>
                  <IonInput
                    type={"number"}
                    inputMode={"numeric"}
                    value={age}
                    required
                    placeholder="Enter Input"
                    onIonChange={(e) => setAge(e.detail.value!)}
                    clearInput
                  ></IonInput>
                </IonItem>
                <IonItemDivider>Gender</IonItemDivider>
                <IonSelect
                  interface={"popover"}
                  style={{ color: "white" }}
                  value={gender}
                  placeholder="Select One"
                  onIonChange={(e) => setGender(e.detail.value)}
                >
                  <IonSelectOption value="Male">Male</IonSelectOption>
                  <IonSelectOption value="Female">Female</IonSelectOption>
                </IonSelect>
                <IonItemDivider>Phone</IonItemDivider>
                <IonItem>
                  <IonInput
                    required
                    inputMode={"tel"}
                    type={"number"}
                    value={phone}
                    placeholder="Enter Input"
                    onIonChange={(e) => setPhone(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItemDivider></IonItemDivider>
                <IonButton type={"submit"}>Edit Patient</IonButton>
              </IonList>
            </form>
          </IonHeader>
          <IonButton onClick={() => setShowEdit(false)}>Close</IonButton>
        </IonModal>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={"Delete " + dValue}
          message={"Do you want to Delete?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setShowAlert(false);
              },
            },
            {
              text: "Yes",
              handler: () => {
                switch (dValue) {
                  case "Temperature":
                    removeTemp({
                      variables: { id },
                    });
                    break;
                  case "Patient":
                    removePatient({
                      variables: { id: match.params.id },
                    });
                    break;
                  case "Oxygen Log":
                    removeOxy({
                      variables: { id },
                    });
                    break;
                }
              },
            },
          ]}
        />

        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="Data has been removed"
          duration={3000}
        />
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Data has been added"
          duration={3000}
        />
        <IonToast
          isOpen={showToastEdit}
          onDidDismiss={() => setShowToast1(false)}
          message="Data has been Edited"
          duration={3000}
        />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
              <IonButton
                onClick={() => {
                  setShowQR(true);
                }}
              >
                <IonIcon slot="icon-only" icon={qrCode} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                onClick={() => {
                  setDValue("Patient");
                  setShowAlert(true);
                }}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
              <IonButton onClick={() => setShowModal(true)}>
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
              <IonButton
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=91" +
                    data.patient.phone
                  )
                }
              >
                <IonIcon slot="icon-only" icon={logoWhatsapp} />
              </IonButton>
            </IonButtons>
            <IonTitle>{data.patient.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon icon={arrowUp} />
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton onClick={() => setShowEdit(true)}>
                <IonIcon icon={pencilOutline} />
              </IonFabButton>
              <IonFabButton onClick={() => { setShowCreateAppointment(true); setAName(data.patient.name); setANumber(data.patient.phone) }}>
                <IonIcon icon={calendarOutline} />
              </IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal
            isOpen={showQR}
            swipeToClose={true}
            onDidDismiss={() => setShowQR(false)}
          >
            <UPI />
            <IonButton onClick={() => setShowQR(false)}>Close</IonButton>
          </IonModal>
          <IonModal isOpen={showModal} swipeToClose={true}>
            {segement === "treatment" && (
              <AddTreatment
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            {segement === "xray" && (
              <AddResource
                type={"X-Ray"}
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            {segement === "report" && (
              <AddResource
                type={"Report"}
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            {segement === "misc" && (
              <AddResource
                type={"Misc"}
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            {segement === "temp" && (
              <AddTemp
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            {segement === "oxy" && (
              <AddOxy
                refetch={refetch}
                setShowModal={setShowModal}
                pid={match.params.id}
                setShowToast1={setShowToast1}
              />
            )}
            <IonButton onClick={() => setShowModal(false)}>Close </IonButton>
          </IonModal>
          <IonSegment
            value={segement}
            onIonChange={(e) => setSegment(e.detail.value as string)}
          >
            <IonSegmentButton value="treatment">
              <IonLabel>Treatments</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="xray">
              <IonLabel>X-Rays</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="report">
              <IonLabel>Reports</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="misc">
              <IonLabel>Misc</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="temp">
              <IonLabel>Temperatures</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="oxy">
              <IonLabel>Saturation</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {segement === "treatment" && (
            <div>
              <IonList>
                {data.patient.treatments.map((treatment: any) => (
                  <IonItem
                    key={treatment.id}
                    routerLink={"/treatment/" + treatment.id}
                  >
                    <IonLabel>
                      <h2>{treatment.title}</h2>
                      <h3>{treatment.date}</h3>
                      <h3>{treatment.time}</h3>
                    </IonLabel>
                    {treatment.prescriptions.length > 0 && (
                      <>
                        <IonIcon
                          style={{ color: "white" }}
                          icon={attachOutline}
                        ></IonIcon>
                      </>
                    )}
                  </IonItem>
                ))}
              </IonList>
            </div>
          )}
          {segement === "xray" && (
            <div>
              <IonList>
                {data.patient.xrays.map((xray: any, index: Number) => (
                  <IonItem key={xray.id} routerLink={"/image/xray/" + xray.url}>
                    <IonLabel>
                      <h2>X-ray {index.valueOf() + 1}</h2>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          )}
          {segement === "report" && (
            <div>
              <IonList>
                {data.patient.reports.map((report: any, index: Number) => (
                  <IonItem
                    key={report.id}
                    routerLink={"/image/report/" + report.url}
                  >
                    <IonLabel>
                      <h2>Report {index.valueOf() + 1}</h2>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          )}
          {segement === "misc" && (
            <div>
              <IonList>
                {data.patient.miscs.map((misc: any, index: Number) => (
                  <IonItem key={misc.id} routerLink={"/image/misc/" + misc.url}>
                    <IonLabel>
                      <h2>Misc {index.valueOf() + 1}</h2>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          )}
          {segement === "temp" && (
            <div>
              {data.patient.temps.map((temp: any, index: Number) => (
                <IonCard
                  onClick={() => {
                    setDValue("Temperature");
                    setId(temp.id);
                    setShowAlert(true);
                  }}
                  key={temp.id}
                  style={
                    temp.value > 99 ? (temp.value > 100 ? red : orange) : green
                  }
                >
                  <IonCardHeader>
                    <IonCardSubtitle style={{ color: "white" }}>
                      <h5>
                        {temp.date}|{temp.time}
                      </h5>
                    </IonCardSubtitle>
                    <IonCardTitle style={{ color: "white" }}>
                      {" "}
                      <h2>{temp.value} °F</h2>
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              ))}
            </div>
          )}
          {segement === "oxy" && (
            <div>
              {data.patient.oxys.map((o: any, index: Number) => (
                <IonCard
                  onClick={() => {
                    setDValue("Oxygen Log");
                    setId(o.id);
                    setShowAlert(true);
                  }}
                  key={o.id}
                  style={o.spo2 < 94 || o.spo2 > 100 ? red : green}
                >
                  <IonCardHeader>
                    <IonCardSubtitle style={{ color: "white" }}>
                      <h5>
                        {o.date}|{o.time}
                      </h5>
                    </IonCardSubtitle>
                    <IonCardTitle style={{ color: "white" }}>
                      {" "}
                      <h2>SpO2: {o.spo2}%</h2>
                      <h2>{o.bpm} bpm</h2>
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              ))}
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
};

export interface addTreatmentProps {
  refetch: any;
  setShowModal: any;
  pid: String;
  setShowToast1: any;
}

const AddTreatment: React.SFC<addTreatmentProps> = (props) => {
  const [addTreatment] = useMutation(ADD_Treatment, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [title, setTitle] = useState<string>();
  const dateObj = new Date();
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  const date = `${day}-${month}-${year}`;
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const mins = dateObj.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${mins}`;

  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Add Treatment</IonTitle>
      </IonToolbar>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addTreatment({ variables: { pid: props.pid, date, time, title } });
          props.setShowModal(false);
        }}
      >
        <IonList>
          <IonItemDivider>Treatment Title</IonItemDivider>
          <IonItem>
            <IonInput
              clearInput
              value={title}
              required
              type={"text"}
              placeholder="Enter Treatment title"
              onIonChange={(e) => setTitle(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider></IonItemDivider>
          <IonButton type={"submit"}>Add Treatment</IonButton>
        </IonList>
      </form>
    </IonHeader>
  );
};

export interface AddXrayProps {
  refetch: any;
  setShowModal: any;
  pid: String;
  setShowToast1: any;
  type: String;
}

const AddResource: React.SFC<AddXrayProps> = (props) => {
  var id: string;
  const [showLoading, setShowLoading] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [idState, setid] = useState<string>();
  const [uploadStatus, setUploadStatus] = useState<string>();
  const storage = useStorage();
  var storageRef = storage.ref();

  let makeid = (length: Number) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const onChangeHandler = (event: any) => {
    const file = event.target.files[0];
    id = makeid(20);
    setid(id);
    console.log(id);
    var uploadTask = storageRef.child(id).put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus("Upload is " + progress + "% done");
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        setShowLoading(false);
        setDoUpdate(true);
        props.setShowModal(false);

        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const onChangeHandlerPDF = (event: any) => {
    const file = event.target.files[0];
    id = makeid(20) + "pdf";
    setid(id);
    console.log(id);
    var uploadTask = storageRef.child(id).put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus("Upload is " + progress + "% done");
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        setShowLoading(false);
        setDoUpdate(true);
        props.setShowModal(false);

        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
        });
      }
    );
  };
  const fileInput = useRef(null);
  const fileInputPDF = useRef(null);
  const [addXray] = useMutation(ADD_XRAY, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [addReport] = useMutation(ADD_REPORT, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [addMisc] = useMutation(ADD_MISC, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  if (doUpdate) {
    switch (props.type) {
      case "X-Ray":
        addXray({ variables: { pid: props.pid, url: idState } });
        break;
      case "Report":
        addReport({ variables: { pid: props.pid, url: idState } });
        break;
      case "Misc":
        addMisc({ variables: { pid: props.pid, url: idState } });
        break;
    }
    setDoUpdate(false);
  }
  return (
    <IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={uploadStatus}
        duration={50000}
      />
      <IonToolbar>
        <IonTitle>Add {props.type}</IonTitle>
      </IonToolbar>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <IonList>
          <IonItemDivider>Upload File</IonItemDivider>
          <IonItem>
            <>
              <input
                ref={fileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={onChangeHandler}
              />
              <IonButton
                color="primary"
                onClick={() => {
                  // @ts-ignore
                  fileInput?.current?.click();
                  // setBackgroundOption(BackgroundOptionType.Gradient);
                }}
              >
                Upload Image
              </IonButton>
              <input
                ref={fileInputPDF}
                hidden
                type="file"
                onChange={onChangeHandlerPDF}
              />
              <IonButton
                color="primary"
                onClick={() => {
                  // @ts-ignore
                  fileInputPDF?.current?.click();
                  // setBackgroundOption(BackgroundOptionType.Gradient);
                }}
              >
                Upload PDF
              </IonButton>
            </>
          </IonItem>
        </IonList>

        <br />
      </form>
    </IonHeader>
  );
};

export interface AddTempProps {
  setShowModal: any;
  pid: String;
  setShowToast1: any;
  refetch: any;
}

const AddTemp: React.SFC<AddTempProps> = (props) => {
  const [value, setValue] = useState<string>();
  const [addTemp] = useMutation(ADD_TEMP, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const dateObj = new Date();
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  const date = `${day}-${month}-${year}`;
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const mins = dateObj.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${mins}`;
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Add Temparature</IonTitle>
      </IonToolbar>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const fval = parseFloat(value!);
          addTemp({ variables: { pid: props.pid, date, time, value: fval } });
          props.setShowModal(false);
          setValue("");
        }}
      >
        <IonList>
          <IonItemDivider>Temperature value</IonItemDivider>
          <IonItem>
            <IonInput
              pattern={"[0-9][0-9][0-9]?.?[0-9]?[0-9]?"}
              clearInput
              value={value}
              required
              type={"text"}
              placeholder="Enter Temperature Value"
              onIonChange={(e) => setValue(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider></IonItemDivider>
          <IonButton type={"submit"}>Add Reading</IonButton>
        </IonList>
      </form>
    </IonHeader>
  );
};

export interface AddOxyProps {
  setShowModal: any;
  pid: String;
  setShowToast1: any;
  refetch: any;
}

const AddOxy: React.SFC<AddOxyProps> = (props) => {
  const [spo2, setSpo2] = useState<string>();
  const [bpm, setBpm] = useState<string>();
  const [addOxy] = useMutation(ADD_OXY, {
    onCompleted() {
      props.setShowToast1(true);
      props.refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const dateObj = new Date();
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  const date = `${day}-${month}-${year}`;
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const mins = dateObj.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${mins}`;
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Add Oxygen Log</IonTitle>
      </IonToolbar>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const sfval = parseFloat(spo2!);
          const bfval = parseFloat(bpm!);
          addOxy({
            variables: { pid: props.pid, date, time, spo2: sfval, bpm: bfval },
          });
          props.setShowModal(false);
          setSpo2("");
          setBpm("");
        }}
      >
        <IonList>
          <IonItemDivider>SpO2 value</IonItemDivider>
          <IonItem>
            <IonInput
              pattern={"[0-9][0-9][0-9]?.?[0-9]?[0-9]?"}
              clearInput
              value={spo2}
              required
              type={"text"}
              placeholder="Enter SpO2 Value"
              onIonChange={(e) => setSpo2(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider>BPM value</IonItemDivider>
          <IonItem>
            <IonInput
              pattern={"[0-9][0-9][0-9]?"}
              clearInput
              value={bpm}
              required
              type={"text"}
              placeholder="Enter BPM Value"
              onIonChange={(e) => setBpm(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemDivider></IonItemDivider>
          <IonButton type={"submit"}>Add Reading</IonButton>
        </IonList>
      </form>
    </IonHeader>
  );
};

export default Patient;
