import React, { useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonModal,
  IonToast,
  IonItemDivider,
  IonLoading,
  IonFab,
  IonFabButton,
  IonInput,
} from "@ionic/react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ExploreContainer from "../components/ExploreContainer";
import { add, trashOutline, pencilOutline } from "ionicons/icons";
import { useStorage } from "reactfire";
import { useSetRecoilState } from "recoil";
import { needsRefreshState } from "./atoms";

export interface TreatmentProps {
  match: any;
  history: any;
}

const TREATMENT_QUERY = gql`
  query($id: ID!) {
    treatment(id: $id) {
      id
      title
      prescriptions {
        id
        url
      }
    }
  }
`;
const ADD_PRESC = gql`
  mutation AddPrescription($tid: String!, $url: String!) {
    addPrescription(tid: $tid, url: $url) {
      id
      url
    }
  }
`;

const REMOVE_TREATMENT = gql`
  mutation RemoveTreatment($tid: String!) {
    removeTreatment(tid: $tid) {
      result
    }
  }
`;

const EDIT_TREAT = gql`
  mutation EditTreat($id: ID, $title: String!) {
    editTreatment(id: $id, title: $title) {
      result
    }
  }
`;

const Treatment: React.SFC<TreatmentProps> = ({ match, history }) => {
  const [editTreat] = useMutation(EDIT_TREAT, {
    onCompleted() {
      setShowToastEdit(true);
      refetch();
      setNeedsRefresh(true);
      history.goBack();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [showToastEdit, setShowToastEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const setNeedsRefresh = useSetRecoilState(needsRefreshState);
  const [removeTreat] = useMutation(REMOVE_TREATMENT, {
    onCompleted() {
      setShowToast2(true);
      history.goBack();
      setNeedsRefresh(true);
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  let makeid = (length: Number) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const fileInput = useRef(null);

  const onChangeHandler = (event: any) => {
    const file = event.target.files[0];
    id = makeid(20);
    setid(id);
    console.log(id);
    var uploadTask = storageRef.child(id).put(file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus("Upload is " + progress + "% done");
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        setShowLoading(false);
        setShowModal(false);
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
        });
        setDoUpdate(true);
      }
    );
  };

  var id: string;
  const [addPresc] = useMutation(ADD_PRESC, {
    onCompleted() {
      setShowToast1(true);
      refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });

  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [title, setTitle] = useState<string>();
  const [idState, setid] = useState<string>();
  const [uploadStatus, setUploadStatus] = useState<string>();
  const storage = useStorage();
  var storageRef = storage.ref();
  const [showModal, setShowModal] = useState(false);
  const { loading, error, data, refetch } = useQuery(TREATMENT_QUERY, {
    variables: { id: match.params.id },
  });
  if (!loading && firstLoad) {
    setTitle(data.treatment.title);
    setFirstLoad(false);
  }
  if (doUpdate) {
    addPresc({
      variables: { tid: match.params.id, url: idState },
    });
    setDoUpdate(false);
  }
  if (loading || error) return <ExploreContainer />;
  else
    return (
      <IonPage>
        <IonToast
          isOpen={showToastEdit}
          onDidDismiss={() => setShowToast1(false)}
          message="Data has been Edited"
          duration={3000}
        />
        <IonModal isOpen={showEdit} cssClass="my-custom-class">
          <IonHeader>
            <IonToolbar>
              <IonTitle>Edit {title}</IonTitle>
            </IonToolbar>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                editTreat({ variables: { id: match.params.id, title } });
                setShowEdit(false);
                refetch();
              }}
            >
              <IonList>
                <IonItemDivider>Treatment Title</IonItemDivider>
                <IonItem>
                  <IonInput
                    clearInput
                    value={title}
                    required
                    type={"text"}
                    placeholder="Enter Treatment title"
                    onIonChange={(e) => setTitle(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItemDivider></IonItemDivider>
                <IonButton type={"submit"}>Edit Treatment</IonButton>
              </IonList>
            </form>
          </IonHeader>
        </IonModal>
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="Data has been removed"
          duration={3000}
        />
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Data has been added"
          duration={3000}
        />
        <IonModal isOpen={showModal} swipeToClose={true}>
          <IonHeader>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={showLoading}
              onDidDismiss={() => setShowLoading(false)}
              message={uploadStatus}
              duration={50000}
            />
            <IonToolbar>
              <IonTitle>Add Prescription</IonTitle>
            </IonToolbar>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <IonList>
                <IonItemDivider>Upload File</IonItemDivider>
                <IonItem>
                  <>
                    <input
                      ref={fileInput}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onChangeHandler}
                    />
                    <IonButton
                      color="primary"
                      onClick={() => {
                        // @ts-ignore
                        fileInput?.current?.click();
                        // setBackgroundOption(BackgroundOptionType.Gradient);
                      }}
                    >
                      Upload Image
                    </IonButton>
                  </>
                </IonItem>
              </IonList>

              <br />
            </form>
          </IonHeader>
          <IonButton onClick={() => setShowModal(false)}>Close </IonButton>
        </IonModal>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton
                onClick={() =>
                  removeTreat({
                    variables: { tid: match.params.id },
                  })
                }
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
              <IonButton onClick={() => setShowModal(true)}>
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonButtons>
            <IonTitle>Prescriptions</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => setShowEdit(true)}>
              <IonIcon icon={pencilOutline} />
            </IonFabButton>
          </IonFab>

          <IonList>
            {data.treatment.prescriptions.map(
              (prescription: any, index: number) => (
                <IonItem
                  key={prescription.id}
                  routerLink={"/image/prescription/" + prescription.url}
                >
                  <IonLabel>Prescription {index + 1}</IonLabel>
                </IonItem>
              )
            )}
          </IonList>
        </IonContent>
      </IonPage>
    );
};

export default Treatment;
