import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FirebaseAppProvider } from "reactfire";
import "bootstrap/dist/css/bootstrap.css";
const firebaseConfig = {
  apiKey: "AIzaSyCLQr5E0pntXtGPRRqWz4tA8M6ftmzKVj4",
  authDomain: "patient-medical-system.firebaseapp.com",
  databaseURL: "https://patient-medical-system.firebaseio.com",
  projectId: "patient-medical-system",
  storageBucket: "patient-medical-system.appspot.com",
  messagingSenderId: "64466382438",
  appId: "1:64466382438:web:cca8e88a2834c1183307a7",
  measurementId: "G-PZFKETC4H8",
};
ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
