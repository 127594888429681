import React from "react";
import "./ExploreContainer.css";
import { IonSpinner } from "@ionic/react";

interface ContainerProps {}

const ExploreContainer: React.FC<ContainerProps> = () => {
  return (
    <div className="container">
      <IonSpinner name="crescent" />
    </div>
  );
};

export default ExploreContainer;
