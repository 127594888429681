import { atom } from "recoil";

const needsRefreshState = atom({
  key: "needsRefresh", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

const refreshRootQueryState = atom({
  key: "refreshRootQuery",
  default: false,
});

export { needsRefreshState, refreshRootQueryState };
