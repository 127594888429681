import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonButton,
  IonToast,
  IonAlert,
} from "@ionic/react";
import { useStorage } from "reactfire";
import { trashOutline } from "ionicons/icons";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useSetRecoilState } from "recoil";
import { needsRefreshState } from "./atoms";
import { Document, Page, pdfjs } from "react-pdf";
import "./image.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const REMOVE_RES = gql`
  mutation RemoveResouce($url: String!, $type: String!) {
    removeResource(url: $url, type: $type) {
      result
    }
  }
`;

export interface ImageViewProps {
  match: any;
  history: any;
}

const ImageView: React.SFC<ImageViewProps> = ({ match, history }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const setNeedsRefresh = useSetRecoilState(needsRefreshState);
  const [removeRes] = useMutation(REMOVE_RES, {
    onCompleted() {
      setShowToast1(true);
      history.goBack();
      setNeedsRefresh(true);
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  const [url, setUrl] = useState<string>();
  const storage = useStorage();
  useEffect(() => {
    if ((match.params.id as string).length === 23) {
      setIsPDF(true);
      console.log("PDF");
    }
  }, [isPDF, match.params.id]);

  storage
    .ref(match.params.id)
    .getDownloadURL()
    .then((url) => setUrl(url));
  console.log(url);
  return (
    <IonPage>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Delete Resource"}
        message={"Do you want to Delete?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowAlert(false);
            },
          },
          {
            text: "Yes",
            handler: () => {
              removeRes({
                variables: { url: match.params.id, type: match.params.type },
              });
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Data has been removed"
        duration={3000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={() => setShowAlert(true)}>
              <IonIcon icon={trashOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Display</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {url && !isPDF && <img src={url} alt="Loading"></img>}
        {url && isPDF && (
          <div style={{ width: "100vw", height: "100%" }}>
            <Document file={encodeURI(url)}>
              <Page pageNumber={1} width={window.innerWidth} />
            </Document>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ImageView;
