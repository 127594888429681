import React from "react";
import "./landing.css";
export interface LandingProps {}

const Landing: React.SFC<LandingProps> = () => {
  return (
    <div className="background">
      <div className="text-center align-middle" style={{ color: "white" }}>
        <img className="logo" src="/assets/logo.png" alt={""} />
      </div>
      <div className="text-center align-middle" style={{ color: "red",position:"relative", top : '3%', fontSize:'1.7vw', fontWeight:"bold" }}>
        Since 1994
      </div>
      <div className="text-center align-middle msdc" style={{ color: "rgb(3,0,255)" }}>
        M S DENTAL CLINIC AND IMPLANT CENTER
      </div>
      <div
        className="text-center addr"
        style={{ color: "rgb(3,0,255)", fontSize: "2vw" }}
      >
        No 25, 15<sup>th</sup> Main Road, 3<sup>rd</sup> Stage, 4<sup>th</sup>{" "}
        Block, Basaveshwarnagar, Bengaluru-79
      </div>
      <div
        className="text-center name"
        style={{ color: "red", fontSize: "1.5vw", marginLeft: "10px" }}
      >
        Dr R Krishna B.Sc BDS
      </div>
      <div
        className="text-center desg"
        style={{ color: "red", fontSize: "1.5vw", marginLeft: "10px" }}
      >
        {" "}
        Dental Surgeon
      </div>
      <div
        className="text-center reg"
        style={{ color: "red", fontSize: "1.5vw", marginLeft: "10px" }}
      >
        Regn No: 2369-A
      </div>{" "}
    </div>
  );
};

export default Landing;
