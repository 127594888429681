import React, { useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonButton,
  IonLoading,
  IonText,
} from "@ionic/react";
import { useAuth } from "reactfire";

export interface LogInFormProps {}

const LogInForm: React.SFC<LogInFormProps> = () => {
  const auth = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Logging In..."}
        duration={10000}
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonText color="primary" class="d-flex justify-content-center">
          <div style={{ fontSize: "90px" }}>M S Dental Clinic</div>
        </IonText>
        <br />
        {!showLogin && (
          <IonButton
            style={{ width: "100%" }}
            onClick={() => setShowLogin(true)}
          >
            Login to MSDC
          </IonButton>
        )}
        {showLogin && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowLoading(true);
              auth
                .signInWithEmailAndPassword(username!, password!)
                .catch((error) => {
                  setShowLoading(false);
                  window.alert(error);
                });
            }}
          >
            <IonItem>
              <IonLabel>Username</IonLabel>
              <IonInput
                name="username"
                required
                type={"email"}
                value={username}
                onIonChange={(e) => setUsername(e.detail.value!)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel>Password</IonLabel>
              <IonInput
                name="password"
                required
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
              ></IonInput>
            </IonItem>
            <br />

            <div>
              <IonButton type={"submit"} expand="block" color="secondary">
                SIGN IN
              </IonButton>
            </div>
          </form>
        )}
      </IonContent>
    </IonPage>
  );
};

export default LogInForm;
