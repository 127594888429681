import { useMutation } from "@apollo/react-hooks";
import { IonHeader, IonToolbar, IonTitle, IonList, IonItemDivider, IonItem, IonInput, IonButton, IonDatetime, IonLabel, IonToast } from "@ionic/react";
import { gql } from "apollo-boost";
import React, { useState } from "react";
import { FC } from "react";

interface CreateAppointmentProps {
    name: string;
    phone: string;
}


const ADD_APPOINTMENT = gql`
  mutation AddPatient(
    $name: String!
    $subject: String!
    $dateString: String!
    $number: String!
  ) {
    addAppointment(name: $name, subject: $subject, dateString: $dateString, number: $number) {
    name
    subject
    date
    time
    number
    }
  }
`;

const CreateAppointment: FC<CreateAppointmentProps> = ({ name, phone }) => {

    const [subject, setSubject] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [showToast1, setShowToast1] = useState(false);
    const [addAppointment] = useMutation(ADD_APPOINTMENT, {
        onCompleted() {
            setShowToast1(true);
        },
        onError() {
            window.alert("An Error occured, Please try again");
        },
    });

    return (
        <IonHeader>
            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message="Appointment has been added"
                duration={3000}
            />
            <IonToolbar>
                <IonTitle>Create Appointment</IonTitle>
            </IonToolbar>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    addAppointment({ variables: { name, subject, dateString:selectedDate, number:phone } });
                }}
            >
                <IonList>
                    <IonItemDivider>Patient Name</IonItemDivider>
                    <IonItem>
                        <IonInput
                            clearInput
                            pattern={"[a-zA-Z ]*"}
                            value={name}
                            required
                            type={"text"}
                            disabled
                        ></IonInput>
                    </IonItem>
                    <IonItemDivider>Phone</IonItemDivider>
                    <IonItem>
                        <IonInput
                            required
                            inputMode={"tel"}
                            type={"number"}
                            value={phone}
                            placeholder="Enter Input"
                            disabled
                        ></IonInput>
                    </IonItem>
                    <IonItemDivider>Subject</IonItemDivider>
                    <IonItem>
                        <IonInput
                            clearInput
                            pattern={"[a-zA-Z ]*"}
                            value={subject}
                            required
                            type={"text"}
                            placeholder="Enter Appointment Subject"
                            onIonChange={(e) => setSubject(e.detail.value!)}
                        ></IonInput>
                    </IonItem>
                    <IonItemDivider>Date and Time</IonItemDivider>
                    <IonItem>
                        <IonLabel position="floating">Appointment Date and Time</IonLabel>
                        <IonDatetime
                            displayFormat="DD-MM-YYYY hh:mm a"
                            minuteValues="0,15,30,45"
                            value={selectedDate}
                            onIonChange={(e) => setSelectedDate(e.detail.value!)}
                        ></IonDatetime>
                    </IonItem>
                    <IonItemDivider></IonItemDivider>
                    <IonButton type={"submit"}>Add Appointment</IonButton>
                </IonList>
            </form>
        </IonHeader>
    )
}

export default CreateAppointment;