import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import Patient from "./pages/Patient";
import { AuthCheck } from "reactfire";
import LogInForm from "./pages/Login";
import ExploreContainer from "./components/ExploreContainer";
import ImageView from "./pages/Image";
import Treatment from "./pages/Treatment";
import { setContext } from "apollo-link-context";
import { RecoilRoot } from "recoil";
import Appointments from "./Appointments";

const httpLink = createHttpLink({
  uri: "https://graph.msdental.in",
});

const authLink = setContext((_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <IonApp>
          <Suspense fallback={<ExploreContainer />}>
            <AuthCheck fallback={<LogInForm />}>
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route path="/home" component={Home} exact={true} />
                  <Route
                    path="/image/:type/:id"
                    component={ImageView}
                    exact={true}
                  />
                  <Route
                    path="/treatment/:id"
                    component={Treatment}
                    exact={true}
                  />
                  <Route path="/patient/:id" component={Patient} exact={true} />
                  <Route path="/appointments" component={Appointments} exact={true} />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                </IonRouterOutlet>
              </IonReactRouter>
            </AuthCheck>
          </Suspense>
        </IonApp>
      </ApolloProvider>
    </RecoilRoot>
  );
};
export default App;
