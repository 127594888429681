import { useMutation, useQuery } from "@apollo/react-hooks";
import { IonAlert, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonDatetime, IonHeader, IonIcon, IonLabel, IonPage, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { gql } from "apollo-boost";
import { trashBinSharp } from "ionicons/icons";
import React, { FC, useState } from "react";
import ExploreContainer from "./components/ExploreContainer";

const APPOINTMENT_QUERY = gql`
  query ($dateString: String!) {
    appointments(dateString: $dateString) {
        id
        name
        date
        time
        number
        subject
    }
  }
`;

const REMOVE_APPOINTMENT = gql`
  mutation removeAppointment($id: ID!) {
    removeAppointment(id: $id) {
      result
    }
  }
`;

const Appointments: FC = () => {
    const [dateString, setDateString] = useState<string>(new Date().toISOString());
    const [showAlert, setShowAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [id, setID] = useState<string>("");
    const { loading, error, data, refetch } = useQuery(APPOINTMENT_QUERY, {
        variables: { dateString },
    });
    const [removeAppointment] = useMutation(REMOVE_APPOINTMENT, {
        async onCompleted() {
            await timeout(2000); //for 2 sec delay
            setShowToast(true);
            refetch();
        },
        onError() {
            window.alert("An Error occured, Please try again");
        },
    });
    return (<IonPage>
        <IonHeader>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message="Appointment has been removed"
                duration={3000}
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={"Delete Appointment"}
                message={"Do you want to Delete?"}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        cssClass: "secondary",
                        handler: () => {
                            setShowAlert(false);
                        },
                    },
                    {
                        text: "Yes",
                        handler: () => {
                            removeAppointment({
                                variables: { id }
                            });
                            refetch();
                        },
                    },
                ]}
            />
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton />
                </IonButtons>
                <IonTitle>Appointments</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            {(loading || error) && <ExploreContainer />}
            {!loading && (

                <div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",width:"100vw",padding:"20px"}}>
                            <IonLabel style={{position:"relative",top:"10px",paddingRight:"20px"}}>Date: </IonLabel>
                            <IonButton style={{display:"flex",justifyContent:"center",alignItems:"center",padding:2}}>
                            <IonDatetime style={{position:"relative",right:8}}
                                onIonChange={(e) => setDateString(e.detail.value!)}
                                displayFormat="DD-MM-YYYY"
                                value={dateString}
                            ></IonDatetime>
                            </IonButton>
                    </div>
                    {data.appointments.map((appointment: any) => (
                        <IonCard
                            key={appointment.id}
                            mode={"ios"}
                            onClick={() => {
                                setID(appointment.id);
                                setShowAlert(true);
                            }}
                        >
                            <IonCardHeader>
                                <IonCardSubtitle>{appointment.time}</IonCardSubtitle>
                                <IonButton style={{ float: "right" }} >
                                    <IonIcon slot="icon-only" icon={trashBinSharp} />
                                </IonButton>
                                <IonCardTitle>
                                    <h5>{appointment.name} - {appointment.subject}</h5>
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Phone: {appointment.number.replace("+91", "")}
                                <br />
                                Date: {appointment.date}
                            </IonCardContent>
                        </IonCard>
                    ))}
                </div>
            )
            }
        </IonContent>
    </IonPage>)
}

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

export default Appointments;