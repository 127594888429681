import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCardContent,
  IonModal,
  IonInput,
  IonList,
  IonItemDivider,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonRow,
  IonCol,
} from "@ionic/react";
import {
  add,
  search,
  exitOutline,
  arrowBackCircle,
  arrowForwardCircle,
  eyeOutline,
  eyeOffOutline,
  qrCode,
  calendarSharp,
} from "ionicons/icons";
import React, { useState } from "react";
import ExploreContainer from "../components/ExploreContainer";
import "./Home.css";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useAuth } from "reactfire";
import { useRecoilState } from "recoil";
import { refreshRootQueryState } from "./atoms";
import Landing from "./landing";
import UPI from "../components/UPI";

const PATIENT_QUERY = gql`
  query ($page: Int!) {
    patients(page: $page) {
      id
      name
      phone
      age
      gender
    }
    length(type: "Patients")
  }
`;

const SEARCH_PATIENT = gql`
  query ($name: String!) {
    patientName(name: $name) {
      id
      name
      phone
      age
      gender
    }
  }
`;

const ADD_PATIENT = gql`
  mutation AddPatient(
    $name: String!
    $age: Int!
    $gender: String!
    $phone: String!
  ) {
    addPatient(name: $name, age: $age, gender: $gender, phone: $phone) {
      id
      name
      phone
      age
      gender
    }
  }
`;

const Home: React.FC = () => {
  let pages: number[] = [];
  const [showModal, setShowModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const auth = useAuth();
  auth.currentUser
    ?.getIdToken()
    .then((token) => localStorage.setItem("token", token));
  const [page, setPage] = useState(0);
  const [name, setName] = useState<string>();
  const [age, setAge] = useState<string>();
  const [hide, setHide] = useState(true);
  const [phone, setPhone] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [showQR, setShowQR] = useState<boolean>(false);
  const [refreshRootQuerry, setRefreshRootQuerry] = useRecoilState(
    refreshRootQueryState
  );
  const { loading, error, data, refetch } = useQuery(PATIENT_QUERY, {
    variables: { page },
  });
  const [addPatient] = useMutation(ADD_PATIENT, {
    onCompleted() {
      setShowToast1(true);
      refetch();
    },
    onError() {
      window.alert("An Error occured, Please try again");
    },
  });
  if (!loading) {
    for (let index = 0; index < Math.ceil(data.length / 10); index++) {
      pages.push(index);
    }
  }
  if (refreshRootQuerry) {
    refetch();
    setRefreshRootQuerry(false);
  }
  return (
    <IonPage>
      <IonModal isOpen={showModal} swipeToClose={true}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Patient</IonTitle>
          </IonToolbar>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let iage = parseInt(age!, 10);
              addPatient({ variables: { name, age: iage, gender, phone } });
              setShowModal(false);
              setName("");
              setAge("");
              setGender("");
              setPhone("");
            }}
          >
            <IonList>
              <IonItemDivider>Patient Name</IonItemDivider>
              <IonItem>
                <IonInput
                  clearInput
                  pattern={"[a-zA-Z ]*"}
                  value={name}
                  required
                  type={"text"}
                  placeholder="Enter Name"
                  onIonChange={(e) => setName(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItemDivider>Age</IonItemDivider>
              <IonItem>
                <IonInput
                  type={"number"}
                  inputMode={"numeric"}
                  value={age}
                  required
                  placeholder="Enter Input"
                  onIonChange={(e) => setAge(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItemDivider>Gender</IonItemDivider>
              <IonSelect
                interface={"popover"}
                style={{ color: "white" }}
                value={gender}
                placeholder="Select One"
                onIonChange={(e) => setGender(e.detail.value)}
              >
                <IonSelectOption value="Male">Male</IonSelectOption>
                <IonSelectOption value="Female">Female</IonSelectOption>
              </IonSelect>
              <IonItemDivider>Phone</IonItemDivider>
              <IonItem>
                <IonInput
                  required
                  inputMode={"tel"}
                  type={"number"}
                  value={phone}
                  placeholder="Enter Input"
                  onIonChange={(e) => setPhone(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItemDivider></IonItemDivider>
              <IonButton type={"submit"}>Add Patient</IonButton>
            </IonList>
          </form>
        </IonHeader>

        <IonButton onClick={() => setShowModal(false)}>Close </IonButton>
      </IonModal>
      <IonModal
        isOpen={showQR}
        swipeToClose={true}
        onDidDismiss={() => setShowQR(false)}
      >
        <UPI />
        <IonButton onClick={() => setShowQR(false)}>Close</IonButton>
      </IonModal>

      <IonModal isOpen={showSearch} swipeToClose={true}>
        <SearchComponent setShowSearch={setShowSearch} />
        <IonButton onClick={() => setShowSearch(false)}>Close</IonButton>
      </IonModal>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Patient has been added"
        duration={3000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                localStorage.clear();
                auth.signOut();
              }}
            >
              <IonIcon slot="icon-only" icon={exitOutline} />
            </IonButton>
            <IonButton
              onClick={() => {
                setShowQR(true);
              }}
            >
              <IonIcon slot="icon-only" icon={qrCode} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton routerLink="/appointments">
              <IonIcon slot="icon-only" icon={calendarSharp} />
            </IonButton>
            <IonButton onClick={() => setHide(hide ? false : true)}>
              <IonIcon
                slot="icon-only"
                icon={hide ? eyeOutline : eyeOffOutline}
              />
            </IonButton>
            <IonButton onClick={() => setShowSearch(true)}>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
            <IonButton onClick={() => setShowModal(true)}>
              <IonIcon slot="icon-only" icon={add} />
            </IonButton>
          </IonButtons>
          <IonTitle>MSDC</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {(loading || error) && <ExploreContainer />}
        {!loading && hide && <Landing />}
        {!loading && !hide && (
          <div>
            {Math.ceil(data.length / 10) > 1 && (
              <IonRow>
                <IonCol size="2">
                  <IonButton
                    style={{ float: "left" }}
                    onClick={() => (page > 0 ? setPage(page - 1) : page)}
                  >
                    <IonIcon icon={arrowBackCircle}></IonIcon>
                  </IonButton>
                </IonCol>
                <IonCol size="8">
                  <IonSelect
                    interface="popover"
                    interfaceOptions={{"cssClass":"my-custom-class"}}
                    value={page}
                    placeholder="Select One"
                    onIonChange={(e) => {
                      setPage(e.detail.value);
                      refetch();
                    }}
                  >
                    {pages.map((num) => (
                      <IonSelectOption key={num} value={num}>
                        Page {num + 1}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
                <IonCol size="2">
                  <IonButton
                    style={{ float: "right" }}
                    onClick={() =>
                      page < Math.ceil(data.length / 10) - 1
                        ? setPage(page + 1)
                        : page
                    }
                  >
                    <IonIcon icon={arrowForwardCircle}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
            {data.patients.map((patient: any) => (
              <IonCard
                key={patient.id}
                mode={"ios"}
                routerLink={"/patient/" + patient.id}
              >
                <IonCardHeader>
                  <IonCardSubtitle>Phone:{patient.phone}</IonCardSubtitle>
                  <IonCardTitle>
                    <h5>{patient.name}</h5>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  Age:{patient.age}
                  <br />
                  Gender:{patient.gender}
                </IonCardContent>
              </IonCard>
            ))}{" "}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const SearchComponent = (props: { setShowSearch: (arg0: boolean) => void }) => {
  const [name, setName] = useState<string>();
  const { loading, error, data } = useQuery(SEARCH_PATIENT, {
    variables: { name },
  });
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Search Patient</IonTitle>
        </IonToolbar>
        <IonItem>
          <IonInput
            clearInput
            pattern={"[a-zA-Z ]*"}
            value={name}
            required
            type={"text"}
            placeholder="Enter Name"
            onIonChange={(e) => setName(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonHeader>
      <IonContent>
        {name && (
          <div>
            {(loading || error) && <ExploreContainer />}
            {!(loading || error) &&
              data.patientName.map((patient: any) => (
                <IonCard
                  key={patient.id}
                  mode={"ios"}
                  onClick={() => props.setShowSearch(false)}
                  routerLink={"/patient/" + patient.id}
                >
                  <IonCardHeader>
                    <IonCardSubtitle>Phone:{patient.phone}</IonCardSubtitle>
                    <IonCardTitle>
                      <h5>{patient.name}</h5>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Age:{patient.age}
                    <br />
                    Gender:{patient.gender}
                  </IonCardContent>
                </IonCard>
              ))}
          </div>
        )}
      </IonContent>
    </>
  );
};

export default Home;
